export default [
  {
    path: '/startup/apply-now',
    name: 'apply-now',
    component: () => import('@/views/start-up/ApplyNow/ApplyNow.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Apply Now',
      breadcrumb: [
        {
          text: 'Startup',
        },
        {
          text: 'Apply Now',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup/apply-now/:id',
    name: 'view-programs',
    component: () => import('@/views/start-up/ApplyNow/ApplyNowInformation.vue'),
    meta: {
      pageTitle: 'Program Information',
      breadcrumb: [
        {
          text: 'Programs',
        },
        {
          text: 'Program Information',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup/apply-now/details/:pid/:apid/:aid?',
    name: 'application-form',
    component: () => import('@/views/programs/programManager/components/applicationManager/ApplicationQuestion.vue'),
    meta: {
      pageTitle: 'Application Questions',
    },
  },
]
