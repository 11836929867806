export default [
  {
    path: '/duediligence/manage',
    name: 'manage-duediligences',
    component: () => import('@/views/duediligence/ManageDueDiligences.vue'),
    meta: {
      pageTitle: 'Manage Due Diligence',
      breadcrumb: [
        {
          text: 'Due Diligence',
          active: true,
        },
      ],
    },
  },
  {
    path: '/duediligence/add',
    name: 'add-duediligences',
    component: () => import('@/views/duediligence/AddDueDiligences.vue'),
    meta: {
      pageTitle: 'Create Purpose',
      breadcrumb: [
        {
          text: 'Due Diligence',
          to: '/duediligence/manage',
        },
        {
          text: 'Create Purpose',
          active: true,
        },
      ],
    },
  },
  {
    path: '/duediligence/add/:id',
    name: 'update-duediligences',
    component: () => import('@/views/duediligence/AddDueDiligences.vue'),
    meta: {
      pageTitle: 'Update Purpose',
      breadcrumb: [
        {
          text: 'Due Diligence',
          to: '/duediligence/manage',
        },
        {
          text: 'Update Purpose',
          active: true,
        },
      ],
    },
  },
  {
    path: '/duediligence/manager/:id',
    name: 'duediligence-manager',
    component: () => import('@/views/duediligence/dueDiligenceManager/DueDiligenceManager.vue'),
    meta: {
      pageTitle: 'Due Dilligence Manager',
      breadcrumb: [
        {
          text: 'Due Diligence',
          to: '/duediligence/manage',
        },
        {
          text: 'Due Dilligence Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/duediligence/review/:id/:sid',
    name: 'duediligence-review-question',
    component: () => import('@/views/duediligence/dueDiligenceManager/ReviewQuestion.vue'),
    meta: {
      pageTitle: 'Review Questions',
      breadcrumb: [
        {
          text: 'Due Dilligence',
        },
        {
          text: 'Review Questions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/duediligence/evaluation/:id/:sid/:pid',
    name: 'duediligence-evaluation-question',
    component: () => import('@/views/duediligence/dueDiligenceManager/EvaluationQuestion.vue'),
    meta: {
      pageTitle: 'Evaluation Questions',
      breadcrumb: [
        {
          text: 'Due Dilligence',
        },
        {
          text: 'Evaluation Questions',
          active: true,
        },
      ],
    },
  },
]
