import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
import resources from './routes/resources'
import events from './routes/events'
import teams from './routes/teams'
import startups from './routes/startups'
import tickets from './routes/tickets'
import finances from './routes/finances'
import fundings from './routes/fundings'
import duediligence from './routes/duediligence'
import networks from './routes/networks'
import programs from './routes/programs'
import startup from './routes/start-up'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard-ecommerce' } },
    ...apps,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    ...resources,
    ...finances,
    ...fundings,
    ...events,
    ...teams,
    ...startups,
    ...duediligence,
    ...tickets,
    ...networks,
    ...programs,
    ...startup,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// TODO: Update Breadcrumbs (Reference Due Diligence)

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

export default router
