export default [
  {
    path: '/programs/manage',
    name: 'manage-programs',
    component: () => import('@/views/programs/ManagePrograms.vue'),
    meta: {
      pageTitle: 'Manage Programs',
      breadcrumb: [
        {
          text: 'Programs',
        },
        {
          text: 'Manage Programs',
          active: true,
        },
      ],
    },
  },
  {
    path: '/programs/add',
    name: 'add-programs',
    component: () => import('@/views/programs/AddPrograms.vue'),
    meta: {
      pageTitle: 'Add Program',
      breadcrumb: [
        {
          text: 'Programs',
        },
        {
          text: 'Add Program',
          active: true,
        },
      ],
    },
  },
  {
    path: '/programs/add/:id',
    name: 'update-programs',
    component: () => import('@/views/programs/AddPrograms.vue'),
    meta: {
      pageTitle: 'Update Program',
      breadcrumb: [
        {
          text: 'Programs',
        },
        {
          text: 'Update Program',
          active: true,
        },
      ],
    },
  },
  {
    path: '/programs/manager/:id',
    name: 'program-manager',
    component: () => import('@/views/programs/programManager/ProgramManager.vue'),
    meta: {
      pageTitle: 'Program Manager',
      breadcrumb: [
        {
          text: 'Programs',
        },
        {
          text: 'Program Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/programs/application/:pid/:apid/:aid?',
    name: 'application-question',
    component: () => import('@/views/programs/programManager/components/applicationManager/ApplicationQuestion.vue'),
    meta: {
      pageTitle: 'Application Questions',
    },
  },
  {
    path: '/programs/application/score/:pid/:apid/:aid',
    name: 'application-score',
    component: () => import('@/views/programs/programManager/components/applicationManager/ApplicationScore.vue'),
    meta: {
      pageTitle: 'Score Response',
    },
  },
  {
    path: '/programs/viewEvaluation/:apid/:aid',
    name: 'view-evaluation',
    component: () => import('@/views/programs/programManager/components/applicationManager/ViewEvaluation.vue'),
    meta: {
      pageTitle: 'View Evaluation',
    },
  },
  {
    path: '/programs/viewIndividualScoring/:apid/:aid/:partner',
    name: 'individual-scoring',
    component: () => import('@/views/programs/programManager/components/applicationManager/IndividualScoring.vue'),
    meta: {
      pageTitle: 'Individual Scoring',
    },
  },
  {
    path: '/programs/viewApplication/:pid/:aid',
    name: 'view-application',
    component: () => import('@/views/programs/programManager/components/applicationManager/ViewApplications.vue'),
    meta: {
      pageTitle: 'View Applications',
    },
  },
  {
    path: '/programs/viewAssignment/:pid',
    name: 'assignments',
    component: () => import('@/views/programs/programManager/components/assignmentManager/ViewAssignments.vue'),
    meta: {
      pageTitle: 'Startup Assignments',
    },
  },
  {
    path: '/programs/assignment/:id/:pid?',
    name: 'assignment-questions',
    component: () => import('@/views/programs/programManager/components/assignmentManager/AssignmentQuestion.vue'),
    meta: {
      pageTitle: 'Assignment Questions',
    },
  },
  {
    path: '/programs/assignment/evaluation/:id/:pid?',
    name: 'assignment-questions',
    component: () => import('@/views/programs/programManager/components/assignmentManager/ViewEvaluation.vue'),
    meta: {
      pageTitle: 'Assignment Evaluations',
    },
  },
  {
    path: '/programs/progress/:id/startups',
    name: 'progress-startups',
    component: () => import('@/views/programs/programManager/components/progressStages/ViewStartups.vue'),
    meta: {
      pageTitle: 'View Startups',
    },
  },
]
