export default [
  {
    path: '/startup/applications',
    name: 'applications',
    component: () => import('@/views/start-up/Applications/Applications.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Applications',
      breadcrumb: [
        {
          text: 'Startup',
        },
        {
          text: 'Applications',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup/applications/details/:id',
    name: 'application-enter',
    component: () => import('@/views/start-up/Applications/Details.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Application details',
      breadcrumb: [
        {
          text: 'Applications',
        },
        {
          text: 'Application details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup/applications/details/:pid/:apid/:aid?',
    name: 'application-form',
    component: () => import('@/views/programs/programManager/components/applicationManager/ApplicationQuestion.vue'),
    meta: {
      pageTitle: 'Application Questions',
    },
  },
]
