export default [
  {
    path: '/networks/manage',
    name: 'manage-networks',
    component: () => import('@/views/networks/ManageNetworks.vue'),
    meta: {
      pageTitle: 'Manage Networks',
      breadcrumb: [
        {
          text: 'Networks',
        },
        {
          text: 'Manage Networks',
          active: true,
        },
      ],
    },
  },
  {
    path: '/networks/add',
    name: 'add-networks',
    component: () => import('@/views/networks/AddNetworks.vue'),
    meta: {
      pageTitle: 'Add Member',
      breadcrumb: [
        {
          text: 'Networks',
        },
        {
          text: 'Add Member',
          active: true,
        },
      ],
    },
  },
  {
    path: '/networks/add/:id',
    name: 'add-networks',
    component: () => import('@/views/networks/AddNetworks.vue'),
    meta: {
      pageTitle: 'Update Network',
      breadcrumb: [
        {
          text: 'Networks',
        },
        {
          text: 'Add Network',
          active: true,
        },
      ],
    },
  },
  {
    path: '/networks/manager',
    name: 'network-manager',
    component: () => import('@/views/networks/networkManager/NetworkManager.vue'),
    meta: {
      pageTitle: 'Network Manager',
      breadcrumb: [
        {
          text: 'Networks',
        },
        {
          text: 'Network Manager',
          active: true,
        },
      ],
    },
  },
]
