export default [
  {
    path: '/startup/program',
    name: 'program',
    component: () => import('@/views/start-up/Programs/Programs.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Programs',
      breadcrumb: [
        {
          text: 'Startup',
        },
        {
          text: 'Programs',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup/program/enter/:id',
    name: 'program-enter',
    component: () => import('@/views/start-up/Programs/Enter/Enter.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Program enter',
      breadcrumb: [
        {
          text: 'Programs',
        },
        {
          text: 'Program enter',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup/program/progress/details/:id',
    name: 'program-progress-details',
    component: () => import('@/views/start-up/Programs/Enter/ProgressDetails.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Program Process',
      breadcrumb: [
        {
          text: 'Programs',
        },
        {
          text: 'Program Process',
          active: true,
        },
      ],
    },
  },
]
