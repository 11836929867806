export default [
  {
    path: '/startups/manage',
    name: 'manage-startups',
    component: () => import('@/views/startups/ManageStartups.vue'),
    meta: {
      pageTitle: 'Manage Startups',
      breadcrumb: [
        {
          text: 'Startups',
        },
        {
          text: 'Manage Startups',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startups/add',
    name: 'add-startups',
    component: () => import('@/views/startups/AddStartups.vue'),
    meta: {
      pageTitle: 'Add Startup',
      breadcrumb: [
        {
          text: 'Startups',
        },
        {
          text: 'Add Startup',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startups/add/:id',
    name: 'add-startups',
    component: () => import('@/views/startups/AddStartups.vue'),
    meta: {
      pageTitle: 'Update Startup',
      breadcrumb: [
        {
          text: 'Startups',
        },
        {
          text: 'Update Startup',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startups/manager',
    name: 'progress-manager',
    component: () => import('@/views/startups/progressManager/ProgressManager.vue'),
    meta: {
      pageTitle: 'Progress Manager',
      breadcrumb: [
        {
          text: 'Progress',
        },
        {
          text: 'Progress Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startups/viewEvaluation/:eId',
    name: 'view-evaluation',
    component: () => import('@/views/startups/progressManager/components/ViewEvaluation.vue'),
    meta: {
      pageTitle: 'View Evaluation',
    },
  },
  {
    path: '/startups/viewReview/:eId',
    name: 'view-review',
    component: () => import('@/views/startups/progressManager/components/ViewReview.vue'),
    meta: {
      pageTitle: 'View Review',
    },
  },
  {
    path: '/startups/viewEvaluation/viewFullEvaluation/:vFEId',
    name: 'view-full-evaluation',
    component: () => import('@/views/startups/progressManager/components/ViewFullEvaluation.vue'),
    meta: {
      pageTitle: 'View Full Evaluation',
    },
  },
  {
    path: '/startups/progressManager/partners/viewActions',
    name: 'view-partner-actions',
    component: () => import('@/views/startups/progressManager/components/ViewActions/ViewActions.vue'),
    meta: {
      pageTitle: 'View Actions',
    },
  },
  {
    path: '/startups/progressManager/partners/viewActions/viewReviews',
    name: 'view-partner-actions-reviews',
    component: () => import('@/views/startups/progressManager/components/ViewActions/ViewActionReview.vue'),
    meta: {
      pageTitle: 'View Reviews',
    },
  },
  {
    path: '/startups/progressManager/partners/viewActions/viewReviews/viewReview',
    name: 'view-partner-actions-review',
    component: () => import('@/views/startups/progressManager/components/ViewActions/ViewReview.vue'),
    meta: {
      pageTitle: 'View Review',
    },
  },
]
